import Vue from 'vue'
import VueRouter from 'vue-router'
import Admin from '../components/Login.vue'
import Index from '../components/Index.vue'
import Welcome from '../components/Welcome.vue'
import Welcome1 from '../components/Welcome1.vue'
import NoAccess from '../components/NoAccess.vue'
import StoreList from '../components/Store/Stores.vue'
import StoreAdd from '../components/Store/Add.vue'
import StoreEdit from '../components/Store/Edit.vue'
import StoreMark from '../components/Store/Mark.vue'
import Swiper from '../components/Store/Swiper.vue'
import Device from '../components/Store/Device.vue'
import UserList from '../components/User/Users.vue'
import RecordList from '../components/User/Records.vue'
import CardList from '../components/Card/Cards.vue'
import OrderList from '../components/Order/Orders.vue'
import OrderMcList from '../components/OrderMc/Orders.vue'
import CoachList from '../components/Coach/Coachs.vue'

import MerchantList from '../components/Merchant/Merchants.vue' //加盟商列表
import InexpList from '../components/Merchant/Inexplist.vue'    //收支明细
import CashList from '../components/Merchant/Cashlist.vue'    //提现管理

import OpenInfo from '../components/Info/Infos.vue'
import Map from '../components/Store/Map.vue'

import Managers from '../components/System/Manager.vue' //设置管理员
import Systems from '../components/System/System.vue' //系统管理
import LogList from '../components/System/Logs.vue' //日志管理
import Message from '../components/Operations/Message.vue' //留言管理
import Trace from '../components/Operations/Trace.vue' //日志追踪
import Faults from '../components/Operations/Fault.vue'    //设备报修管理
import Differ from '../components/Customer/Differ.vue' //异业合作

import Test from '../components/Test.vue'    //测试页

 // ------------------------加盟商------------------------------
import JoinLogin from '../components/Join/JoinLogin.vue' //加盟商登录
import JoinIndex from '../components/Join/JoinIndex.vue' //加盟商首页
import JoinWelcome from '../components/Join/JoinWelcome.vue' //加盟商欢迎页
import StoreCard from '../components/Join/Setting/StoreCard.vue' //门店上架会员卡
import StoreUsers from '../components/Join/User/Users.vue' //门店会员
import StoreOrder from '../components/Join/Order/Orders.vue' //门店订单
import StoreDiffer from '../components/Join/Customer/Differ.vue' //异业合作




Vue.use(VueRouter)

const routes = [

  { path: '/test', component:Test },
  { path: '/no-access', component:NoAccess },
  { path: '/admin', component:Admin },
  { path: '/index', component: Index, redirect: (to) => {
    const tokenStr = window.sessionStorage.getItem('admin_token');
    
    // 如果是admin用户，则重定向到/welcome，否则重定向到/welcome1
    if (tokenStr === 'admin') {
      return '/welcome';
    } else {
      return '/welcome1';
    }
  }, children:[
    { path:'/welcome', component:Welcome},
    { path:'/welcome1', component:Welcome1},
    { path:'/stores', component:StoreList},//门店列表 
    { path:'/storeadd', component:StoreAdd},//添加门店
    { path:'/storeedit/:id', component:StoreEdit},//编辑门店
    { path:'/storemark/:id', component:StoreMark},//标注门店
    { path:'/swiper', component:Swiper},//轮播图设置
    { path:'/device', component:Device},//轮播图设置
    { path:'/users', component:UserList},//用户列表 
    { path:'/records', component:RecordList},//进出记录列表 
    { path:'/cards', component:CardList},//会员卡列表 
    { path:'/orders', component:OrderList},//订单列表
    { path:'/mcorders', component:OrderMcList},//模拟订单列表
    { path:'/coachs', component:CoachList},//助教列表
    { path:'/merchants', component:MerchantList}, //加盟商列表
    { path:'/inexplist', component:InexpList},   //加盟商收支明细
    { path:'/cashlist', component:CashList},   //加盟商提现管理
    { path:'/managers', component:Managers},   //设置管理员
    { path:'/systems', component:Systems},   //系统管理
    { path:'/logs', component:LogList},   //日志管理
    
    { path:'/trace', component:Trace},   //日志追踪
    { path:'/messages', component:Message},   //留言管理
    { path:'/faults', component:Faults},   //故障报修管理
    { path:'/map', component:Map},//地图
    { path:'/openinfo', component:OpenInfo},//开店信息
    { path:'/differ', component:Differ},//异业合作

  ]},

 // ------------------------加盟商------------------------------
  { path: '/store', component:JoinLogin },
  { path: '/joinindex', component: JoinIndex, redirect:'/joinwelcome', children:[
    { path:'/joinwelcome', component:JoinWelcome},
    { path:'/storecard', component:StoreCard},//会员卡设置
    { path:'/storeusers', component:StoreUsers},//会员管理
    { path:'/storeorder', component:StoreOrder},//订单管理    
    { path:'/storediffer', component:StoreDiffer},//拓客管理    
  ]},
  
]

const router = new VueRouter({
  routes
})


router.beforeEach((to, from, next) => {
  
  // 动态检查 token
  const tokenStr = window.sessionStorage.getItem('admin_token');
  const adminPermissions  = window.sessionStorage.getItem('perid_token');
  const jointokenStr = window.sessionStorage.getItem('join_token');
  if (adminPermissions && adminPermissions.trim() !== '') {
    const permissions = JSON.parse(adminPermissions.replace(/'/g, '"')); // 替换单引号为双引号并解析
    // console.log(permissions);
      // 路由与权限的对应关系
    const routePermissionsMap = {
      '/welcome': '财务统计',
      '/stores': '门店管理',
      '/swiper': '门店管理',
      '/merchants': '加盟商管理',
      '/inexplist': '加盟商管理',
      '/users': '会员管理',
      '/cards': '会员卡管理',
      '/orders': '订单管理',
      '/openinfo': '开店信息',
      '/coachs': '助教管理',
      '/managers': '系统管理',
      '/systems': '系统管理',
      '/logs': '系统管理',
      '/messages': '运营管理',
      '/faults': '运营管理',
    };
    // 获取当前路由所需的权限
    const requiredPermission = routePermissionsMap[to.path];
    if (requiredPermission && !permissions.includes(requiredPermission)) {
      return next('/no-access'); // 跳转到登录页或无权限提示页
    }
  }
  
  // 如果访问的是管理员相关的路径（如 /admin），不需要 token 检查
  if (to.path === '/admin') return next();

  // 如果访问的是加盟商相关路径（如 /join 和 /joinindex），跳过 token 检查
  if (to.path.startsWith('/store')) return next();
  

  if (to.path.startsWith('/test')) return next();

  // 如果既没有管理员 token，也没有加盟商 token，重定向到登录页
  if (!tokenStr && !jointokenStr) {
    return next('/'); // 假设根路径为登录页
  } else {
    next();
  }
});

export default router
