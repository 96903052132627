<script>
    // const imgURL = 'https://jianshenhtapi.girlfee.com/';
    // const imgURL_Front = 'https://jianshenhtapi.girlfee.com/';
    const imgURL='https://jianshenhtapi.tieshouyundong.com/';
    const imgURL_Front = 'https://jianshenapi.tieshouyundong.com/';
    // const imgURL='http://127.0.0.1:8008/';
    // const imgURL_Front = 'http://127.0.0.1:8008/';
    
    
    export default
    {
        imgURL,
        imgURL_Front
    }
</script>