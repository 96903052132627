<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>订单列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!--面包屑导航区域-->
        <!--卡片视图区-->
        <el-card>
            <!--搜索与添加区域-->
            <el-row :gutter="20">
                <el-col :span="20">
                    <el-input placeholder="请输入订单编号/用户名/卡名" clearable v-model="queryInfo.query" @clear="getOrderList()" style="width: 300px;">
                        <el-button slot="append" icon="el-icon-search" @click="getOrderList()"></el-button>
                    </el-input>
                    <el-select v-model="queryInfo.query1"  placeholder="请选择门店" @change="getOrderList()"  style="margin-left:10px" clearable  filterable>
                        <el-option v-for="item in storelist" :key="item.id" :label="item.storename" :value="item.id"></el-option>
                    </el-select>
                    <el-date-picker
                        v-model="customdate"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions" @change="CustomeDate" style="margin: 0 10px">
                    </el-date-picker>
                    <el-select  v-model="queryInfo.query2" clearable  placeholder="请选择交易类型" @change="getOrderList()"  style="width:150px">
                            <el-option label="平台" value="0"></el-option>
                            <el-option label="抖音" value="1"></el-option>
                            <el-option label="美团" value="2"></el-option>
                            <el-option label="赠送" value="3"></el-option>
                            <el-option label="分享" value="4"></el-option>
                            <el-option label="异业" value="5"></el-option>
                            <el-option label="退款" value="6"></el-option>
                    </el-select>
                    <el-select  v-model="queryInfo.query3" clearable  placeholder="是否失效" @change="getOrderList()"  style="margin: 0 10px;width:150px">
                        <el-option label="有效" value="0"></el-option>
                        <el-option label="失效" value="1"></el-option>
                    </el-select>
                    <el-button type="danger" icon="el-icon-present" @click="GiftCard">手动发卡</el-button>
                </el-col>
                <el-col :span="4" style="text-align: right;">
                    <download-excel :data = "exclelist" :fields = "excel_fields" name = "订单列表">
                        <el-button type="primary" icon="el-icon-upload" @click="CheckDate">导出Excel</el-button>
                    </download-excel>
                </el-col>
            </el-row>
            <br>
            <!--搜索与添加区域-->
            <!--用户列表区域-->
            <el-table :data="orderlist" style="width: 100%" ref="invoiceTable" stripe size="small" height="600" :summary-method="getSummaries"
            show-summary v-loading="isLoading">
                <!-- <el-table-column label="ID" type="index"  width="50" fixed></el-table-column> -->
                <el-table-column label="序号" width="50" fixed>
                    <template slot-scope="scope">
                        {{ (queryInfo.pagenum - 1) * queryInfo.pagesize + scope.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column label="订单编号" prop="orderno" width="140"></el-table-column>
                <el-table-column label="用户名" prop="username" width="100"></el-table-column>
                <el-table-column label="店铺名" prop="storename" width="150" :show-overflow-tooltip="true"></el-table-column>
                <!-- <el-table-column label="卡名" prop="cardname" width="150" :show-overflow-tooltip="true"></el-table-column> -->
                <el-table-column label="卡名" prop="cardname" width="260" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <div style="display: flex;">
                            <div style="padding-right: 5px;line-height: 30px;">
                                {{ scope.row.cardname }}
                            </div>
                            <div>
                                <el-tag type="danger" v-if="scope.row.istuikuan == '1'">退款</el-tag>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="金额" prop="amount" width="180">
                    <template slot-scope="scope" v-if="scope.row.amount">
                        <span style="color: #ff0000;">￥{{scope.row.amount}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="来源" prop="cardfrom" width="220">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.cardfrom == '0'">平台</el-tag>
                        <el-tag type="danger" v-if="scope.row.cardfrom == '1'">抖音</el-tag>
                        <el-tag type="warning" v-if="scope.row.cardfrom == '2'">美团</el-tag>
                        <span v-if="scope.row.cardfrom == '1' || scope.row.cardfrom == '2'" style="padding-left: 10px;">劵码：{{ scope.row.extra }}</span>
                        <el-tag type="primary" v-if="scope.row.cardfrom == '3'">赠送</el-tag>
                        <el-tag type="info" v-if="scope.row.cardfrom == '4'">分享</el-tag>
                        <el-tag type="info" v-if="scope.row.cardfrom == '5'" effect="dark">异业</el-tag>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="开始时间" prop="starttime" width="150"></el-table-column> -->
                <el-table-column label="收款商户" prop="mchid" width="100"></el-table-column>
                <el-table-column label="状态" prop="status"  width="80">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.status == '0'">有效</el-tag>
                        <el-tag type="info" v-if="scope.row.status == '1'">失效</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="开始时间" prop="starttime" width="150"></el-table-column>
                <el-table-column label="截止时间" prop="endtime" width="150"></el-table-column>
                <el-table-column label="订单状态" prop="status" width="90">
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.paytus==1" type="success">已支付</el-link>
                        <el-link v-else type="warning">未支付</el-link>
                    </template>
                </el-table-column>
                <el-table-column label="订单日期" prop="adddate" width="150" fixed="right"></el-table-column>
                <el-table-column label="操作" width="200" fixed="right">
                    <template slot-scope="scope">
                        <span v-if="scope.row.cardfrom == 0 || scope.row.cardfrom == 1 || scope.row.cardfrom == 2 ">
                            <el-button type="warning" v-if="scope.row.istuikuan == 0" icon="el-icon-delete" size="mini" @click="DelCard(scope.row.id)">删卡</el-button>
                        </span>
                        <span v-if="scope.row.cardfrom == 3 || scope.row.cardfrom == 4 || scope.row.cardfrom == 5 ">
                            <el-button type="warning" v-if="scope.row.status == 0" icon="el-icon-delete" size="mini" @click="DelCard(scope.row.id)">删卡</el-button>
                        </span>
                        <span style="padding: 10px;">
                            <el-button type="danger" v-if="scope.row.status == 0 && scope.row.cardclass == '时间卡'" icon="el-icon-video-pause" size="mini" @click="StopCard(scope.row.id)">停卡</el-button>
                        </span>
                    </template>
                </el-table-column>
            </el-table>
            <!--用户列表区域-->
            <!--分页区域-->
            <br>
            <div align="center">
                  <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[10,50,100,200,500,1000,2000,5000,10000]"  :page-size="queryInfo.pagesize" 
                layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination>
                <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[1, 5, 10, 20, 50, 100]"  :page-size="queryInfo.pagesize" 
                layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination> -->
            </div>
        </el-card>
        <!--发卡对话框-->
        <el-dialog title="手动发卡" :visible.sync="giftDialogVisible" width="60%" @close="giftDialogClosed">
                    <el-form :model="giftForm" :rules="giftFormRules" ref="giftFormRef" label-width="100px">
                        <el-form-item label="手机号" prop="username">
                            <el-select v-model="giftForm.username" filterable  multiple placeholder="请选择会员手机号" style="width: 80%;" :remote="true" :remote-method="fetchUsernames" :loading="loading">
                                <el-option
                                v-for="item in usernames"
                                :key="item.id"
                                :label="item.username"
                                :value="item.username">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="会员卡" prop="cardid">
                            <el-select v-model="giftForm.cardid" placeholder="请选择会员卡" style="width: 80%;">
                                <el-option
                                v-for="item in cards"
                                :key="item.id"
                                :label="item.cardname"
                                :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="使用门店" prop="storeid">
                            <el-select v-model="giftForm.storeid" placeholder="请选择门店" style="width: 80%;">
                                <el-option
                                v-for="item in stores"
                                :key="item.id"
                                :label="item.storename"
                                :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <!-- <el-form-item label="赠送数量" prop="quantity">
                            <el-input-number v-model="giftForm.quantity" :min="1" :max="10" label="描述文字"></el-input-number>
                        </el-form-item> -->
                        <!-- <el-form-item label="备注" prop="remark">
                            <el-input type="textarea" v-model="giftForm.remark" placeholder="此备注内容会以系统消息的方式发给客户。如：***赠送月卡一张。" style="width: 80%;"></el-input>
                        </el-form-item> -->
                    </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="giftDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="SaveGiftCard">确 定</el-button>
                </span>
        </el-dialog>
        <!--发卡对话框-->
    </div>
</template>
<script>
export default {
    data(){
        return{
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            customdate:'',
            //获取订单列表的参数对象
            queryInfo: {
                query: '',
                query1: '',
                query2: '',
                query3: '',
                startdate: '',
                enddate: '',
                pagenum: 1,
                pagesize: 10
            },
            orderlist: [],
            storelist:[],
            total: 0,
            giftDialogVisible:false,
            giftForm:{
                username:[],
                cardid:'',
                storeid:'',
                // quantity:1,
                remark:''
            },
            giftFormRules:{
                username:[
                    { required: true, message: '请选择手机号', trigger: 'blur' }
                ],
                cardid:[
                    { required: true, message: '请选择手机号', trigger: 'blur' }
                ],
                storeid:[
                    { required: true, message: '请选择门店', trigger: 'blur' }
                ],
            },
            usernames: [],
            loading: false,
            cards:[],
            stores:[],
            isLoading:true,
            exclelist:[],
            excel_fields: {  //导出Excel表格的表头设置
                '订单编号':{
                    field:'orderno',
                    callback:value => {
                        return '&nbsp;'+value;
                    } 
                },
                '门店名称': 'storename',
                '用户名': 'username',
                '卡名':'cardname',
                '金额': 'amount',
                '来源': 'cardfrom',
                '劵码': {
                    field:'extra',
                    callback:value => {
                        return '&nbsp;'+value;
                    } 
                },
                '是否退款':'istuikuan',
                '收款商户':'mchid',
                '开始时间':'starttime',
                '截止时间':'endtime',
                '订单日期': 'adddate'
            }
        }
    },
    created() {
         this.getOrderList()
         this.GetStore()
    },
    methods:{
        async GetStore(){
            const { data: res } = await this.$http.get('store/')
            if(res.status == 0){
                this.storelist = res.data
            }
       },
        async getOrderList() {
           const { data: res} = await this.$http.get('orderlist/',{ params: this.queryInfo })
           if(res.status == 1){
                this.orderlist = res.data.list
                this.total = res.data.totalCount;
                this.orderlist = res.data.list.map(v => {
                //在返回的数据里增加一条数据
                // console.log(v.fields)
                return v.fields
                })
            }
            for (let index = 0; index < res.data.list.length; index++) {
                    this.orderlist[index].id= res.data.list[index].pk;
                }
            this.isLoading = false
            // console.log(this.orderlist)
       },
        handleSizeChange(newSize){
            this.isLoading = true
          // console.log(newSize)
           this.queryInfo.pagesize = newSize
           this.getOrderList()
       },
       handleCurrentChange(newPage){
           this.isLoading = true
          // console.log(newPage)
           this.queryInfo.pagenum = newPage
           this.getOrderList()
       },
       CopyText(row){
            var oInput = document.createElement('input')
            oInput.value = row.cons_address +' '+ row.cons_name +' '+ row.cons_telephone
            document.body.appendChild(oInput)
            oInput.select()
            document.execCommand("Copy")
            oInput.className = 'oInput'
            oInput.style.display = 'none'
            this.$message.success('复制成功！')
        },
        async RevokeOrder(id){
            const confirmResult = await this.$confirm('是否要撤销此订单吗?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                        }).catch(err => err)

                        if (confirmResult !== 'confirm'){
                            return this.$message.info('已取消删除！')
                        }
                        const { data: res } = await this.$http.get('orderrevoke/'+ id)
                        // console.log(res)
                        if (res.status === 0) { 
                            this.$message({message: '撤销成功！',type: 'success'})
                        }
                        this.getOrderList()
        },
       async DelOrder(id){
                const confirmResult = await this.$confirm('是否要删除此订单吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).catch(err => err)

                    if (confirmResult !== 'confirm'){
                        return this.$message.info('已取消删除！')
                    }
                    const { data: res } = await this.$http.get('orderdel/'+ id)
                    // console.log(res)
                    if (res.status === 0) { 
                        this.$message({message: '删除成功！',type: 'success'})
                    }
                    this.getOrderList()
       },
        CustomeDate(){
            // console.log(this.customdate);
            if(this.customdate == null){
                this.queryInfo.startdate = ""
                this.queryInfo.enddate = ""
            }else{
                this.queryInfo.startdate = this.customdate[0]
                this.queryInfo.enddate = this.customdate[1]
                this.getExcleData()
            }
            this.getOrderList()
        },
        async getExcleData(){
                const { data:res } = await this.$http.get('orderexclist/',{ params: this.queryInfo })
                if(res.status == 0){
                    this.exclelist = res.data
                    console.log(this.exclelist);
                }
        },
        giftDialogClosed(){
                this.$refs.giftFormRef.resetFields()
        },
        GiftCard(){
            this.giftDialogVisible = true
            this.getCards()
            this.getStores()
        },
        async fetchUsernames(query) {
            if (query === '') {
            this.usernames = [];
            return;
            }
            this.loading = true;
            const { data: res } = await this.$http.get('getusernames/', { params:{ query:query } });
            if (res.status == 0) {
                this.usernames = res.data;
            }
            this.loading = false;
        },
        async getCards(){
            const { data: res } = await this.$http.get('cards/')
            if (res.status == 0) {
                // this.cards = res.data
                this.cards = res.data.filter(card => card.cardclassid !== 3);
                // console.log(this.cards);
            }
        },
        async getStores(){
            const { data: res } = await this.$http.get('store/')
            if (res.status == 0) {
                this.stores = res.data
            }
        },
        async SaveGiftCard(){
            console.log(this.giftForm);
            this.$refs.giftFormRef.validate(async valid => {
                    if (!valid) return
                    // console.log(this.giftForm);
                    this.giftForm.admin_token = window.sessionStorage.getItem('admin_token') 
                    const { data: res } = await this.$http.post('giftcard/',this.giftForm)
                    if (res.status === 0) {
                        this.$message.success('发卡成功')
                        this.giftDialogVisible = false
                        this.getOrderList()
                    }
                })
        },
        getSummaries(param) {
            this.$nextTick(() => {
                this.$refs.invoiceTable.doLayout(); // invoiceTable替换为你的el-table设置的ref值
            });
            // console.log(param);
            const { columns, data } = param;
            const sums = [];
            
            columns.forEach((column, index) => {
                // 如果是第一列，显示 "总价"
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                
                // 只计算 `amount` 列的合计
                if (column.property === 'amount') {
                    const values = data.map(item => Number(item[column.property]));
                    let total = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + value; // 累加合计值
                        } else {
                            return prev;
                        }
                    }, 0);
                    sums[index] = `${total.toFixed(1)} 元`; // 保留一位小数后加单位
                } else {
                    sums[index] = ''; // 非 `amount` 列不显示合计
                }
            });

            return sums;
        },
        CheckDate(){
                if (this.customdate.length == 0){
                    this.$message.error('请先选择日期,最多支持导出一个月的流水！')
                }else{
                    
                    const startDate = new Date(this.customdate[0]);
                    const endDate = new Date(this.customdate[1]);
                
                    // 计算一个月后的日期
                    const oneMonthLater = new Date(startDate);
                    oneMonthLater.setMonth(startDate.getMonth() + 1);
                    console.log(startDate);
                    console.log(endDate);
                    if (endDate > oneMonthLater) {
                        this.$message.error('只能导出一个月的流水！');
                        this.exclelist = []
                    }
                }
        },
        async DelCard(id){ 
            const admin_token = window.sessionStorage.getItem('admin_token') 
            const confirmResult = await this.$confirm('是否要删除此用户会员卡吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).catch(err => err)

            if (confirmResult !== 'confirm'){
                return this.$message.info('已取消删除！')
            }
            const { data: res } = await this.$http.post('orderdel/',{ admin_token:admin_token,id:id})
        
            if (res.status === 0) { 
                this.$message({message: '删除成功！',type: 'success'})
            }
            this.getOrderList()
        },
        StopCard(id) {
            const admin_token = window.sessionStorage.getItem('admin_token') 
            this.$prompt('请输入停卡时长（天）', '停卡设置', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^\d+$/, // 只允许输入正整数
                inputErrorMessage: '请输入有效的天数'
            }).then(async({ value }) => {
                const { data: res} = await this.$http.post('orderstop/',{ admin_token:admin_token,id:id,days: value })
                if (res.status === 0) { 
                    this.$message({message: '停卡设置成功！',type: 'success'})
                }
                if (res.status === 1) { 
                    this.$message({message: '设置失败！',type: 'error'})
                }
                if (res.status === 2) { 
                    this.$message({message: '此卡已过期',type: 'error'})
                }
                this.getOrderList()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });       
            });
        }
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
    }
    .el-breadcrumb{
        width: 100%;
    }
</style>