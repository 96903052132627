<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>轮播图管理</el-breadcrumb-item>
            <el-breadcrumb-item>轮播图列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!--面包屑导航区域-->
        <!--卡片视图区-->
        <el-card>
            <!--搜索与添加区域-->
            <el-row :gutter="20">
                <el-col :span="20">
                    <el-select v-model="queryInfo.query"  placeholder="请选择门店" @change="getInfoList"  style="width: 280px;" clearable  filterable>
                        <el-option v-for="item in storelist" :key="item.id" :label="item.storename" :value="item.id"></el-option>
                    </el-select>
                    <!-- <el-input placeholder="请输入关键字" clearable v-model="queryInfo.query" @clear="getInfoList()">
                        <el-button slot="append" icon="el-icon-search" @click="getInfoList()"></el-button>
                    </el-input> -->
                </el-col>
                <el-col :span="4"><el-button icon="el-icon-plus" type="primary" @click=" addDialogVisible = true">添加轮播图</el-button></el-col>
            </el-row>
            
            <!--搜索与添加区域-->
            <!--列表区域-->
            <el-table :data="infolist" :key="infolist.index" style="width: 100%" stripe size="middle" height="700">

                <el-table-column label="ID" type="index"  width="50"></el-table-column>
                <el-table-column label="标题" prop="title"  width="200"></el-table-column>
                <el-table-column label="图片" prop="title"  :width="queryInfo.query == '' ? '300' :'1100'">
                    <!-- <template slot-scope="scope" v-if="scope.row.pic">
                        <div class="photo"><img :src="imgURL+'static/pics/'+scope.row.pic"></div>
                    </template> -->
                    <template slot-scope="scope" v-if="scope.row.pic">
                        <el-popover placement="bottom"  width="340" trigger="click">
                            <div style="text-align: center;"><img :src="imgURL+'static/swipers/'+scope.row.pic" style="width:300px"></div>
                            <img :src="imgURL+'static/swipers/'+scope.row.pic" style="width:50px" slot="reference">
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="链接类型" prop="urltype"  width="150" v-if="queryInfo.query == ''">
                    <template slot-scope="scope">
                        <span  v-if="scope.row.urltype == '1'">公众号</span>
                        <span  v-if="scope.row.urltype == '2'">视频号</span>
                        <span  v-if="scope.row.urltype == '3'">小红书</span>
                        <span  v-if="scope.row.urltype == '4'">H5</span>
                    </template>
                </el-table-column>
                <el-table-column label="链接" prop="url"  width="600" v-if="queryInfo.query == ''"></el-table-column>
                <!-- <el-table-column label="发布日期" prop="adddate"  width="200"></el-table-column> -->
                <el-table-column label="操作">
                    <!--作用域插槽-->
                    <template slot-scope="scope">
                            <el-button type="success" icon="el-icon-edit" size="mini" @click="EditInfo(scope.row.id)"></el-button>
                            <el-button type="danger" icon="el-icon-delete" size="mini" @click="DelInfo(scope.row.id)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--列表区域-->
            <!--分页区域-->
            <br>
            <div align="center">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[1, 5, 10, 20]"  :page-size="queryInfo.pagesize" 
                layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination>
            </div>
            <!--分页区域-->
            <!--添加对话框-->
            <el-dialog title="添加轮播图" :visible.sync="addDialogVisible" width="60%" top="50px" @close="addDialogClosed">
                <el-form :model="addForm" :rules="addFormRules" ref="addFormRef"  label-width="100px">
                    <el-form-item label="选择门店" prop="storeid">
                        <el-select v-model="addForm.storeid"  placeholder="请选择门店"  style="width: 280px;" clearable>
                            <el-option v-for="item in storelist" :key="item.id" :label="item.storename" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="轮播图标题" prop="title">
                        <el-input v-model="addForm.title"></el-input>
                    </el-form-item>
                    <el-form-item label="轮播图图片" prop="goods_pic">
                        <!-- {{uploadurl}} -->
                        <el-upload
                            ref="imgBroadcastUpload"
                            :limit="limitNum"
                            :auto-upload="true"
                            :file-list="fileList"
                            :on-success="addPic"
                            list-type="picture-card"
                            accept="image/jpg/png"
                            :action="uploadurl"
                            :data="{folderType: 'swipers'}">
                                <i class="el-icon-plus"></i>
                                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过2M</div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="链接类型" prop="urltype" v-if="addForm.storeid == ''">
                        <el-select  v-model="addForm.urltype"  placeholder="请选择类型" style="width:300px">
                            <el-option  label="公众号" value="1"></el-option>
                            <el-option  label="视频号" value="2"></el-option>
                            <el-option  label="小红书" value="3"></el-option>
                            <el-option  label="H5" value="4"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="addForm.urltype == 2 ? '视频ID': '链接'" prop="url" v-if="addForm.storeid == ''">
                        <el-input v-model="addForm.url"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer" style="margin:0">
                    <el-button @click="addDialogVisible = false">取 消</el-button>
                    <el-button @click="AddInfo" style="background:#19aa8d;color:#fff">确 定</el-button>
                </span>
            </el-dialog>
            <!--添加对话框-->
            <!-- 编辑对话框 -->
            <el-dialog title="编辑轮播图" :visible.sync="editDialogVisible" width="60%" top="50px" @close="editDialogClosed">
                <el-form :model="editForm" :rules="editFormRules" ref="editFormRef"  label-width="100px">
                    <el-form-item label="轮播图标题" prop="title">
                        <el-input v-model="editForm.title"></el-input>
                    </el-form-item>
                    <el-form-item label="轮播图图片" prop="goods_pic">
                        
                        <el-upload
                            ref="imgBroadcastUpload"
                            :limit="limitNum"
                            :auto-upload="true"
                            :file-list="fileList"
                            :on-success="editPic"
                            list-type="picture-card"
                            accept="image/jpg/png"
                            :action="uploadurl"
                            :data="{folderType: 'swipers'}">
                                <i class="el-icon-plus"></i>
                                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过2M</div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="链接类型" prop="urltype" v-if="editForm.storeid == ''">
                        <el-select  v-model="editForm.urltype"  placeholder="请选择类型" style="width:300px">
                            <el-option  label="公众号" value="1"></el-option>
                            <el-option  label="视频号" value="2"></el-option>
                            <el-option  label="小红书" value="3"></el-option>
                            <el-option  label="H5" value="4"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="editForm.urltype == 2 ? '视频ID': '链接'" prop="url" v-if="editForm.storeid == ''">
                        <el-input v-model="editForm.url"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer" style="margin:0">
                    <el-button @click="editDialogVisible = false">取 消</el-button>
                    <el-button @click="EditInfoSave" style="background:#19aa8d;color:#fff">确 定</el-button>
                </span>
            </el-dialog>
        </el-card>
    </div>
</template>
<script>
export default {
    data() {
        return{
            imgURL:this.GLOBAL.imgURL,
            uploadurl: this.GLOBAL.imgURL+'upload/',
            limitNum: 1,
            fileList:[],
            infolist: [],
            addDialogVisible:false,
            editDialogVisible:false,
            tag: false,
            //获取列表的参数对象
            queryInfo: {
                query: '',
                pagenum: 1,
                pagesize: 10
            },
            total: 0,
            //添加表单数据
            addForm:{
                storeid:'',
                title:'',
                urltype:'',
                url:''
                
            },
            editForm: {},

            //添加表单验证规则
            addFormRules:{
                title:[
                    { required: true, message: '请输入轮播图标题', trigger: 'blur' }
                ]
            },
            editFormRules:{
                title:[
                    { required: true, message: '请输入轮播图标题', trigger: 'blur' }
                ]
            },
            storelist:[]
        }
        
    },
    mounted(){
        this.getInfoList()   
        this.getStoreList()
        this.addForm.admin_token = window.sessionStorage.getItem('admin_token') 
    },
    methods:{
        async getStoreList(){
            const { data: res } = await this.$http.get('store/')
            this.storelist = res.data
        },
        async getInfoList() {

            const { data: res} = await this.$http.get('swiperlist/',{ params: this.queryInfo })

            if(res.status == 1){
                 this.total = res.data.totalCount;
                 this.infolist = res.data.list.map(v => {
                 return v.fields
                 })
             }
             for (let index = 0; index < res.data.list.length; index++) {
                     this.infolist[index].id= res.data.list[index].pk;
                 }
            
         },
        addPic(file, fileList){
            this.addForm.pic = file.picname
        },
        editPic(file, fileList){
            this.editForm.pic = file.picname
        },
        AddInfo(){
            this.$refs.addFormRef.validate( async valid=>{
                if (!valid) return
                    const { data:res } = await this.$http.post('swiperadd/',this.addForm)
                    if(res.status===0)
                    {
                        this.$message.success('信息添加成功！')
                        this.addDialogVisible = false
                        // location.reload()
                        this.getInfoList()
                    }
            })
        },
        async EditInfo(id){
            const { data: res } = await this.$http.get('swiperinfo/' + id)
            this.editForm = res.data
            this.editForm.admin_token =window.sessionStorage.getItem('admin_token')

            this.editDialogVisible = true
            
        },
        EditInfoSave(){
            this.$refs.editFormRef.validate(async valid => {
                if (!valid) return
                const { data: res } = await this.$http.post('swiperedit/' + this.editForm.id,this.editForm)
                if (res.status === 0) {
                    this.$message.success('信息修改成功')
                    this.editDialogVisible = false
                    this.getInfoList()
                }
            })
        },
        async DelInfo(id){
            const confirmResult = await this.$confirm('是否要删除此信息吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).catch(err => err)

                if (confirmResult !== 'confirm'){
                    return this.$message.info('已取消删除！')
                }
                const { data: res } = await this.$http.get('swiperdel/'+ id,{ params:{admin_token:this.addForm.admin_token} })
            
                if (res.status === 0) { 
                    this.$message({message: '删除成功！',type: 'success'})
                }
                this.getInfoList()
        },
        
        handleSizeChange(newSize){
            this.queryInfo.pagesize = newSize
            this.getInfoList()
        },
        handleCurrentChange(newPage){
            this.queryInfo.pagenum = newPage
            this.getInfoList()
        },
        addDialogClosed(){
                this.$refs.addFormRef.resetFields()
        },
        editDialogClosed(){
                this.$refs.editFormRef.resetFields()
        }
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
    }
    .el-breadcrumb{
        width: 100%;
    }
    /deep/.el-dialog__body{
        padding-bottom:0px !important;
    }
</style>