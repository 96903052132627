<template>
    <div>
         <!--面包屑导航区域-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>门店管理</el-breadcrumb-item>
            <el-breadcrumb-item>器械列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!--面包屑导航区域-->
        <!--卡片视图区-->
        <el-card>
            <!--搜索与添加区域-->
            <el-row :gutter="20" style="margin: 20px 0;">
                <el-col :span="20">
                    <el-select v-model="queryInfo.query"  placeholder="请选择门店" @change="getInfoList"  style="width: 280px;" clearable  filterable>
                        <el-option v-for="item in storelist" :key="item.id" :label="item.storename" :value="item.id"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="4"><el-button icon="el-icon-plus" type="primary" @click=" addDialogVisible = true">添加门店器械</el-button></el-col>
            </el-row>
            <!--搜索与添加区域-->
            <!--列表区域-->
            <el-table :data="infolist" :key="infolist.index" style="width: 100%" stripe size="middle" height="700">

                <el-table-column label="ID" type="index"  width="50"></el-table-column>
                <el-table-column label="门店名称" prop="storename"  width="300"></el-table-column>
                <el-table-column label="器械类别" prop="classname"  width="200"></el-table-column>
                <el-table-column label="器械数据" prop="devicedata"  width="800"></el-table-column>
                <el-table-column label="操作">
                    <!--作用域插槽-->
                    <template slot-scope="scope">
                            <el-button type="success" icon="el-icon-edit" size="mini" @click="EditInfo(scope.row.id)"></el-button>
                            <el-button type="danger" icon="el-icon-delete" size="mini" @click="DelInfo(scope.row.id)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--列表区域-->
            <!--分页区域-->
            <br>
            <div align="center">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[1, 5, 10, 20]"  :page-size="queryInfo.pagesize" 
                layout="total, sizes, prev, pager, next" :total="total">
                </el-pagination>
            </div>
            <!--分页区域-->
            <!--添加对话框-->
            <el-dialog title="添加门店器械" :visible.sync="addDialogVisible" width="60%" top="150px" @close="addDialogClosed">
                <div style="height: 500px;overflow-y: auto">
                    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef"  label-width="100px">
                        <el-form-item label="选择门店" prop="storeid">
                            <el-select v-model="addForm.storeid"  placeholder="请选择门店"  style="width: 280px;" clearable>
                                <el-option v-for="item in storelist" :key="item.id" :label="item.storename" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="选择类别" prop="classid">
                            <el-select v-model="addForm.classid"  placeholder="请选择器械类别"  style="width: 280px;" clearable>
                                <el-option v-for="item in classlist" :key="item.id" :label="item.classname" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            v-for="(owner, index) in addForm.owners"
                            :label="'器械名称' + (index + 1)"
                            :key="owner.key"
                            :prop="'owners.' + index + '.devicename'"
                            :rules="{ required: true, message: '器械名称不能为空', trigger: 'blur' }">
                            <el-input v-model="owner.devicename" placeholder="名称" style="width: 300px; margin:0 10px ;"></el-input>
                            <el-input-number v-model="owner.devicenumber" :min="1" style="margin:0 10px ;"></el-input-number>
                            <el-button @click.prevent="removeOwner(owner)">删除</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer" style="margin:0">
                    <el-button @click="addOwner">新增器械</el-button>
                    <el-button @click="AddInfo" style="background:#19aa8d;color:#fff">确 定</el-button>
                </span>
            </el-dialog>
            <!--添加对话框-->
            <!-- 编辑对话框 -->
            <el-dialog title="编辑门店器械" :visible.sync="editDialogVisible" width="60%" top="50px" @close="editDialogClosed">
                <div style="height: 500px;overflow-y: auto">
                    <el-form :model="editForm" :rules="editFormRules" ref="editFormRef"  label-width="100px">
                        <el-form-item label="选择门店" prop="storeid">
                            <el-select v-model="editForm.storeid"  placeholder="请选择门店"  style="width: 280px;" clearable disabled>
                                <el-option v-for="item in storelist" :key="item.id" :label="item.storename" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="选择类别" prop="classid">
                            <el-select v-model="editForm.classid"  placeholder="请选择器械类别"  style="width: 280px;" clearable disabled>
                                <el-option v-for="item in classlist" :key="item.id" :label="item.classname" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                            <el-form-item
                                v-for="(owner, index) in editForm.devicedata"
                                :label="'器械名称' + (index + 1)"
                                :key="owner.key"
                                :prop="'devicedata.' + index + '.devicename'"
                                :rules="{ required: true, message: '器械名称不能为空', trigger: 'blur' }">
                                <el-input v-model="owner.devicename" placeholder="名称" style="width: 300px; margin:0 10px ;"></el-input>
                                <el-input-number v-model="owner.devicenumber" :min="1" style="margin:0 10px ;"></el-input-number>
                                <el-button @click.prevent="removeOwner1(owner)">删除</el-button>
                            </el-form-item>
                        
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer" style="margin:0">
                    <el-button @click="addOwner1">新增器械</el-button>
                    <el-button @click="EditInfoSave" style="background:#19aa8d;color:#fff">确 定</el-button>
                </span>
            </el-dialog>
        </el-card>
    </div>
</template>
<script>
export default {
    data() {
        return{
            imgURL:this.GLOBAL.imgURL,
            uploadurl: this.GLOBAL.imgURL+'upload/',
            limitNum: 1,
            fileList:[],
            infolist: [],
            addDialogVisible:false,
            editDialogVisible:false,
            tag: false,
            //获取列表的参数对象
            queryInfo: {
                query: '',
                pagenum: 1,
                pagesize: 10
            },
            total: 0,
            //添加表单数据
            addForm:{
                storeid:'',
                classid:'',
                owners: [
                    {
                        devicename: '',
                        devicenumber:'',
                        key: Date.now()
                    }
                ]
                
            },
            editForm: {},

            //添加表单验证规则
            addFormRules:{
                storeid:[
                    { required: true, message: '请选择门店', trigger: 'blur' }
                ],
                classid:[
                    { required: true, message: '请选择类别', trigger: 'blur' }
                ]
            },
            editFormRules:{
                title:[
                    { required: true, message: '请输入轮播图标题', trigger: 'blur' }
                ]
            },
            storelist:[],
            classlist:[]
        }
        
    },
    mounted(){
        this.getInfoList()   
        this.getStoreList()
        this.getClassList()
        this.addForm.admin_token = window.sessionStorage.getItem('admin_token') 
    },
    computed:{
        
    },
    methods:{
        async getStoreList(){
            const { data: res } = await this.$http.get('store/')
            this.storelist = res.data
        },
        async getClassList(){
            const { data: res } = await this.$http.get('deviceclass/')
            this.classlist = res.data
        },
        async getInfoList() {

            const { data: res} = await this.$http.get('devicelist/',{ params: this.queryInfo })

            if(res.status == 1){
                 this.total = res.data.totalCount;
                 this.infolist = res.data.list.map(v => {
                 return v.fields
                 })
             }
             for (let index = 0; index < res.data.list.length; index++) {
                     this.infolist[index].id= res.data.list[index].pk;
                 }
            
         },
        addPic(file, fileList){
            this.addForm.pic = file.picname
        },
        editPic(file, fileList){
            this.editForm.pic = file.picname
        },
        addOwner() {
            this.addForm.owners.push({
                devicename: '',
                devicenumber:'',
                key: Date.now()
            })
        },
        removeOwner(item) {
            const index = this.addForm.owners.indexOf(item);
            if (this.addForm.owners.length > 1 && index !== -1) {
                this.addForm.owners.splice(index, 1);
            }
        },
        addOwner1() {
            this.editForm.devicedata.push({
                devicename: '',
                devicenumber:'',
                key: Date.now()
            })
        },
        removeOwner1(item) {
            const index = this.editForm.devicedata.indexOf(item);
            if (this.editForm.devicedata.length > 1 && index !== -1) {
                this.editForm.devicedata.splice(index, 1);
            }
        },
        AddInfo(){
            this.$refs.addFormRef.validate( async valid=>{
                if (!valid) return
                    const { data:res } = await this.$http.post('deviceadd/',this.addForm)
                    if(res.status===0)
                    {
                        this.$message.success('添加成功！')
                        this.addDialogVisible = false
                        this.addForm.owners = [{
                            devicename: '',
                            devicenumber:'',
                            key: Date.now()
                        }]
                        this.getInfoList()
                    }else{
                        this.$message({message: '此门店器械类别已经添加',type: 'error'})
                    }
            })
        },
        async EditInfo(id){
            const { data: res } = await this.$http.get('deviceinfo/' + id)
            this.editForm = res.data
            
            const validJsonStr = res.data.devicedata.replace(/'/g, '"');
            this.editForm.devicedata = JSON.parse(validJsonStr)
            this.editForm.admin_token =window.sessionStorage.getItem('admin_token')

            this.editDialogVisible = true
            
        },
        EditInfoSave(){
            
            this.$refs.editFormRef.validate(async valid => {
                if (!valid) return
                const { data: res } = await this.$http.post('deviceedit/' + this.editForm.id,this.editForm)
                if (res.status === 0) {
                    this.$message.success('信息修改成功')
                    this.editDialogVisible = false
                    this.getInfoList()
                }
            })
        },
        async DelInfo(id){
            const confirmResult = await this.$confirm('是否要删除此信息吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).catch(err => err)

                if (confirmResult !== 'confirm'){
                    return this.$message.info('已取消删除！')
                }
                const { data: res } = await this.$http.get('devicedel/'+ id,{ params:{admin_token:this.addForm.admin_token} })
            
                if (res.status === 0) { 
                    this.$message({message: '删除成功！',type: 'success'})
                }
                this.getInfoList()
        },
        
        handleSizeChange(newSize){
            this.queryInfo.pagesize = newSize
            this.getInfoList()
        },
        handleCurrentChange(newPage){
            this.queryInfo.pagenum = newPage
            this.getInfoList()
        },
        addDialogClosed(){
                this.$refs.addFormRef.resetFields()
        },
        editDialogClosed(){
                this.$refs.editFormRef.resetFields()
        }
    }
}
</script>
<style scoped>
    .cardcss{
        margin: 5px auto ;
        width: 100%;
    }
    .el-breadcrumb{
        width: 100%;
    }
    /deep/.el-dialog__body{
        padding-bottom:0px !important;
    }
</style>